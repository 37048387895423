import React from 'react';
import { createRoot } from 'react-dom/client';
// import 'react-quill/dist/quill.snow.css';
import "antd/dist/antd.css";
import App from 'App';
import { BrowserRouter } from "react-router-dom";
import "./dashlite.css";
import "./index.css";
import store from 'store';
import { Provider } from 'react-redux'
// import reportWebVitals from './reportWebVitals';

const container = document.querySelector('.nk-app-root');
const root = createRoot(container);
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();